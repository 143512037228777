import { constants } from "@/utils/constants";
import { FromSmallestUnit } from "@/utils/currency";
import { PriceSchedulePrice } from "../../../common/models/price-schedule.models";

/**
 * Calculates the total retail tax amount from the provided price schedule.
 * If taxes are not present or retail taxes are not present returns a constant indicating taxes are not provided
 */
export function calculateRetailTaxTotal(priceSchedulePrice: PriceSchedulePrice): string {
  // Return if taxes are not provided
  if (!priceSchedulePrice?.includedTaxes?.length) {
    return constants.PRICE_NOT_PROVIDED;
  }

  // Process each tax in the price schedule and calculate the total retail
  let totalRetailTax = 0;
  let currency = "USD";
  let hasValidRetailTax = false;
  priceSchedulePrice.includedTaxes.forEach((tax: any) => {
    if (tax?.retail != null && tax?.currency != null) {
      totalRetailTax += tax.retail;
      currency = tax.currency;
      hasValidRetailTax = true;
    }
  });

  // Determine final result
  return hasValidRetailTax ? `${FromSmallestUnit(totalRetailTax, currency)} ${currency}` : constants.PRICE_NOT_PROVIDED;
}

/**
 * Checks whether all price schedule prices have a defined retail value.
 *
 * @param {PriceSchedulePrice[]} priceSchedulePrices - An array of price schedule prices to be validated.
 * @return {boolean} Returns true if all price schedule prices have a non-null and non-undefined retail value, otherwise false.
 */
export function allPricesHaveRetail(priceSchedulePrices: PriceSchedulePrice[]): boolean {
  if (!priceSchedulePrices.length) {
    return false;
  }
  return priceSchedulePrices.every((price) => price.retail !== undefined && price.retail !== null);
}
