import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import productsClient from "@/api/products/ProductsClient";
import _ from "lodash";
import availabilityClient from "@/api/booking/AvailabilityClient";
import moment from "moment-timezone";
import holdClient from "@/api/booking/HoldClient";
import { AcquireHoldRQ, AcquireHoldRS, HoldItem } from "@/api/booking/AcquireHoldModels";
import { AvailabilityTimeSlot, BookingRQ, CartItem, Customer, Item, GuestDetail, PricingInfoData, Traveler, Rate, Ext3 } from "@/api/booking/BookingModels";
import { Product } from "@/api/products/ProductModels";
import bookingClient from "@/api/booking/BookingClient";
import { appModule } from "@/store/modules/moduleApp";
import { codes } from "@/utils/codeConstants";
import { ParkReservationStatus, PickupInstructions, ResellerRegion, VoucherPDFRQ } from "@/api/voucher/VoucherModels";
import voucherClient from "@/api/voucher/VoucherClient";
import { profileModule } from "@/store/modules/moduleProfile";
import { getCompanyContact, getPickupInstruction, mapDiscountGroups, mapMarketRegions, reservationStatus } from "@/utils/helpers";
import { Supplier } from "@/api/suppliers/SupplierModels";
import resellerClient from "@/api/reseller/ResellerClient";
import { Reseller } from "@/api/reseller/ResellerModels";
import { calculateTicketValidityDays } from "@/utils/bookingData";
import { posModule } from "@/store/modules/modulePos";
import { Account as UserProfile } from "@/api/backplane/BackplaneModels";
import { constants } from "@/utils/constants";
import { formatMDY } from "@/filters/date";
import { Logger } from "@/api/logging/logger";
import { DropdownItem } from "@/models";
import {
  EXT_BETA_AVAILABILITY_PRICING_TYPE,
  EXT_DISNEY_BULK_TICKET_DELIVERY_DATE,
  EXT_DISNEY_BULK_TICKET_DELIVERY_INSTRUCTIONS,
  EXT_DISNEY_BULK_TICKET_DELIVERY_TYPE,
  EXT_DISNEY_BULK_TICKET_PURCHASE,
  EXT_DISNEY_DATE_BASED_TICKET,
  EXT_DISNEY_DISCOUNT_GROUPS,
  EXT_DISNEY_MARKET_REGIONS,
  EXT_DISNEY_PARK_RESERVATION_DATE,
  EXT_DISNEY_PARK_RESERVATIONS_REQUIRED,
  EXT_RESELLER_PORTAL_AGENT_EMAIL,
  EXT_RESELLER_PORTAL_AGENT_NAME,
  EXT_RESELLER_PORTAL_RESELLER_NAME,
  EXT_RESELLER_PORTAL_RESELLER_REGION,
  EXT_DISNEY_PRODUCT_DURATION,
  EXT_DISNEY_BOOKING_BUFFER_DAYS,
  EXT_BETA_TRAVELER_WEIGHT,
  EXT_GRAND_CANYON_GROUP_HOTEL_ID,
  EXT_GRAND_CANYON_GROUP_HOTEL_NAME,
  EXT_RESELLER_PORTAL_PICKUP_LOCATION_NAME,
  EXT_GRAND_CANYON_GROUP_PICKUP_LOCATION,
  EXT_RESELLER_PORTAL_PICKUP_LOCATION_ADDRESS,
  EXT_GRAND_CANYON_GROUP_TIME_LOCAL,
  EXT_RESELLER_PORTAL_PICKUP_LOCATION_TIME,
} from "@/api/booking/Extensions";
import { getDisneyTicketEndDate, getDisneyTicketStartDate } from "@/api/booking/ExtensionUtils";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "@/utils/config";
import { httpModule } from "@/store/modules/moduleHttp";
import { stripeModule } from "@/store/modules/stripeModule";
import { CheckoutSessionRQ } from "@/api/stripe/stripe.models";
import { companyModule } from "@/store/modules/moduleCompany";
import { ItemPerTraveler } from "@/utils/pickupLocationUtils";
import { allPricesHaveRetail } from "@/utils/priceScheduleUtils";

/**
 * EmailConfirmationParams - email confirmation parameters
 */
interface EmailConfirmationParams {
  emailTemplate: string;
  bookingID: string;
  skipEmail: boolean;
  sendToAgent: boolean;
  sendToCustomer: boolean;
  withNetPrices: boolean;
  withRetailPrices: boolean;
}

@Module({
  dynamic: true,
  store,
  name: "rdm-rc-addbooking",
  namespaced: true,
})
class ModuleBookingAdd extends VuexModule {
  private logger = new Logger();

  /* Data */
  private loading = false;
  private resellerData: Reseller | null = null;
  private loadingBooking = false;
  private ageRanges: any = [];

  private availabilityError = false;

  private selectedDate = "";

  private companyPhoneNumber = "";

  private productsList: Product[] = [];

  private selectedProductsList: any[] = [];

  private ratesList: any[] = [];

  private noOfDays: any[] = [];

  private selectedCardVal = "";

  private selectedProductStart = "1";

  private selectedProductEnd = "1";

  private ticketTypes: any[] = [];

  private selectedTicketType = "";

  private cards = [];

  private ticketCategory = [];

  private selectedTicketCategory = [];

  private selectedSupplier: Supplier | null = null;
  private isDisney = false;

  private pricingInfoHeaders = {
    type: "Traveler Type",
    pricePerTicket: "Price<br/> (per ticket)",
    taxesFees: "Taxes & Fees<br/> (per ticket)",
    total: "Total",
  };

  private pricingInfoData: PricingInfoData[] = [];

  private pricingCardHeaders = {
    type: "Traveler Type",
    pricePerTicket: "Price (per ticket)",
  };

  private pricingCardData: any[] = [];

  private pricingTotal = 0;

  private currency = "";

  private selectedProductID = "";

  private selectedPriceSchedule: any = [];

  private selectedRateID = "";

  private availabilityRS: any = [];

  private holdRS: AcquireHoldRS = {};

  private itemsList = [];

  private customerData: Customer = {};

  private travelersData: GuestDetail[] = [];

  private includePricing = "true";

  private agentFacing = "true";

  private bookingID = "";

  private booking: any = {};

  private productNames: any[] = [];

  private selectedProductName = "all";

  private marketRegions = [];
  private deactiveTickets: any[] = [];
  private selectedMarketRegions = "All";

  private discountGroups = [];

  private selectedDiscountGroups = "All";

  private selectedRate = {} as Rate;

  private selectedProduct: any = {};

  private minMaxTravelerErr = false;

  private travelerTypes = [];

  private selectedPolicyDescription = "";

  private selectedParks = [] as any;
  private selectedParkDays = [] as Array<string>;
  private allowedDates = [] as Array<string>;

  /** Disney Bulk Tickets */
  private bulkTickets = false;
  private bulkTicketDeliveryType = "";
  private bulkTicketDeliveryInstructions = "";
  private bulkTicketDeliveryDate = "";

  /* Grand Canyon Group*/
  private isGrandCanyonGroup = false;

  /** Reseller Regions **/
  private selectedResellerRegion: ResellerRegion = ResellerRegion.UnitedStates;
  private resellerRegions: DropdownItem[] = [] as Array<DropdownItem>;
  get getSelectedResellerRegion(): ResellerRegion {
    return this.selectedResellerRegion;
  }
  @Mutation
  setSelectedResellerRegion(selection: ResellerRegion) {
    this.selectedResellerRegion = selection;
  }
  get getResellerRegions(): DropdownItem[] {
    const regions: DropdownItem[] = [
      { text: "Argentina", value: ResellerRegion.Argentina },
      { text: "Australia", value: ResellerRegion.Australia },
      { text: "Brazil", value: ResellerRegion.Brazil },
      { text: "Canada (excluding Quebec)", value: ResellerRegion.Canada },
      { text: "Chile", value: ResellerRegion.Chile },
      { text: "Colombia", value: ResellerRegion.Colombia },
      { text: "Europe (excluding United Kingdom and Ireland)", value: ResellerRegion.Europe },
      { text: "Ireland", value: ResellerRegion.Ireland },
      { text: "Japan", value: ResellerRegion.Japan },
      { text: "Mexico", value: ResellerRegion.Mexico },
      { text: "New Zealand", value: ResellerRegion.NewZealand },
      { text: "Peru", value: ResellerRegion.Peru },
      { text: "Quebec", value: ResellerRegion.Quebec },
      { text: "United Kingdom", value: ResellerRegion.UnitedKingdom },
      { text: "United States", value: ResellerRegion.UnitedStates },
      { text: "All other regions not mentioned above", value: ResellerRegion.Other },
    ];
    return regions;
  }

  /**
   * selectedOptionAvailabilityTimeSlots - represents the avail time slots for the selected option
   */
  private selectedOptionAvailabilityTimeSlots: Array<AvailabilityTimeSlot> = [] as Array<AvailabilityTimeSlot>;

  get getSelectedOptionAvailabilityTimeSlots(): Array<AvailabilityTimeSlot> {
    return this.selectedOptionAvailabilityTimeSlots;
  }

  @Mutation
  setSelectedOptionAvailabilityTimeSlots(slots: Array<AvailabilityTimeSlot>) {
    this.selectedOptionAvailabilityTimeSlots = slots;
  }

  /**
   * userSelectedAvailabilityTimeSlot - represents the avail time slot the that user selected
   */
  private userSelectedAvailabilityTimeSlot: AvailabilityTimeSlot;

  get getUserSelectedAvailabilityTimeSlot(): AvailabilityTimeSlot {
    return this.userSelectedAvailabilityTimeSlot;
  }

  @Mutation
  setUserSelectedAvailabilityTimeSlot(slot: AvailabilityTimeSlot) {
    if (slot == null || Object.keys(slot).length === 0 || slot.id == "") {
      return;
    }
    this.userSelectedAvailabilityTimeSlot = slot;

    // filter ps data to the selected time slot
    const localStartTime = this.userSelectedAvailabilityTimeSlot.getLocalStartTime();
    const priceSchedule = this.selectedPriceSchedule;
    const filteredByTime = priceSchedule.filter((item: any) => {
      const startTimes: string[] = Array.isArray(item.startTimes) ? item.startTimes : ([] as Array<string>);
      if (startTimes.length === 0 || startTimes.includes(localStartTime)) {
        return true;
      }
    });
    this.timeFilteredPriceSchedule = filteredByTime;
  }

  /**
   * timeFilteredPriceSchedule - the price scheduled data filtered down to the specific time slot that the user selected
   */
  private timeFilteredPriceSchedule: any;

  get getTimeFilteredPriceSchedule(): any {
    return this.timeFilteredPriceSchedule;
  }

  /**
   * NEW CART STUFF
   */
  private cart: CartItem[] = [] as Array<CartItem>;

  get getCart(): Array<CartItem> {
    return this.cart;
  }

  get IsGrandCanyonGroup(): boolean {
    return companyModule.FeatureFlags?.grandCanyonGroupSupplierId === posModule.SelectedSupplierID;
  }

  @Mutation
  setCart(cartItems: Array<CartItem>) {
    this.cart = cartItems;
  }

  @Mutation
  clearCart() {
    this.cart = [];
  }

  get getTotalTravelersInCart(): number {
    let totalTravelers = 0;
    this.cart.forEach((item: CartItem) => {
      totalTravelers += item.quantity || 0;
    });
    return totalTravelers;
  }

  get isCartEmpty(): boolean {
    return this.cart.length === 0;
  }

  /* Getters */
  get IsDisney(): boolean {
    return this.isDisney;
  }

  get SelectedParks(): any {
    return this.selectedParks;
  }

  get SelectedParkDays(): Array<string> {
    return this.selectedParkDays;
  }

  /**
   * true if the total tickets does not meet the min/max travelers for the rate
   */
  get MinMaxTravelerErr(): boolean {
    return this.minMaxTravelerErr;
  }

  get ResellerData() {
    return this.resellerData;
  }

  get Loading(): boolean {
    return this.loading;
  }

  get User(): UserProfile | null {
    return profileModule.Profile;
  }

  get LoadingBooking(): boolean {
    return this.loadingBooking;
  }

  get CompanyPhoneNumber(): string {
    return this.companyPhoneNumber;
  }

  get Pos(): any {
    return posModule.Pos;
  }

  get ChannelID() {
    return posModule.ChannelID;
  }

  get DistributorCode() {
    return posModule.DistributorCode;
  }

  get SelectedPOS(): any {
    return posModule.SelectedPOS;
  }

  get SelectedSupplierID() {
    return posModule.SelectedSupplierID;
  }

  get SelectedCardVal() {
    return this.selectedCardVal;
  }

  get TicketTypes() {
    return this.ticketTypes;
  }

  get Cards() {
    return this.cards;
  }

  get PricingInfoHeaders() {
    return this.pricingInfoHeaders;
  }

  get PricingInfoData() {
    return this.pricingInfoData;
  }

  get PricingCardHeaders() {
    return this.pricingCardHeaders;
  }

  get PricingCardData() {
    return this.pricingCardData;
  }

  get PricingTotal() {
    return this.pricingTotal;
  }

  get Currency() {
    return this.currency;
  }

  get ProductsList() {
    return this.productsList;
  }

  get SelectedProductsList() {
    return this.selectedProductsList;
  }

  get RatesList() {
    return this.ratesList;
  }

  get SelectedSupplier() {
    return this.selectedSupplier;
  }

  get SelectedDate() {
    return this.selectedDate;
  }

  get SelectedPriceSchedule() {
    return this.selectedPriceSchedule;
  }

  get SelectedProductStart() {
    return this.selectedProductStart;
  }

  get SelectedProductEnd() {
    return this.selectedProductEnd;
  }

  get SelectedPolicyDescription() {
    return this.selectedPolicyDescription;
  }

  get SelectedRateID() {
    return this.selectedRateID;
  }

  get AvailabilityRS() {
    return this.availabilityRS;
  }

  get HoldRS(): AcquireHoldRS {
    return this.holdRS;
  }

  get ItemsList() {
    return this.itemsList;
  }

  get CustomerData() {
    return this.customerData;
  }

  get TravelersData() {
    return this.travelersData;
  }

  get AvailabilityError() {
    return this.availabilityError;
  }

  get SelectedTicketType() {
    return this.selectedTicketType;
  }

  get SelectedTicketCategory() {
    return this.selectedTicketCategory;
  }

  get TicketCategory() {
    return this.ticketCategory;
  }

  get IncludePricing() {
    return this.includePricing;
  }

  get AgentFacing() {
    return this.agentFacing;
  }

  get BookingID(): string {
    return this.bookingID;
  }

  get Booking(): any {
    return this.booking;
  }

  get ProductNames() {
    return this.productNames;
  }

  get SelectedProductName() {
    return this.selectedProductName;
  }

  get MarketRegions() {
    return this.marketRegions;
  }

  get DiscountGroups() {
    return this.discountGroups;
  }

  get SelectedMarketRegions() {
    return this.selectedMarketRegions;
  }

  get SelectedDiscountGroups() {
    return this.selectedDiscountGroups;
  }

  get SelectedRate(): Rate {
    return this.selectedRate;
  }

  get SelectedProduct() {
    return this.selectedProduct;
  }

  get DeactiveTickets() {
    return this.deactiveTickets;
  }

  get TravelerTypes() {
    return this.travelerTypes;
  }

  get AgeRanges() {
    return this.ageRanges;
  }

  get AllowedDates() {
    return this.allowedDates;
  }

  /* Setters */
  @Mutation
  setIsDisney(isDisney: boolean) {
    this.isDisney = isDisney;
  }

  @Mutation
  setSelectedParks(parks: Array<any>) {
    this.selectedParks = parks;
  }

  @Mutation
  setSelectedParkDays(days: Array<string>) {
    this.selectedParkDays = days;
  }

  @Mutation
  setCompanyPhoneNumber(phNumber: string) {
    this.companyPhoneNumber = phNumber;
  }

  @Mutation
  setAgeRanges(data: any) {
    this.ageRanges = data;
  }

  @Mutation
  setDeactiveTickets(deactiveTickets: any) {
    this.deactiveTickets = deactiveTickets;
  }

  @Mutation
  setResellerData(data: any) {
    this.resellerData = data;
  }

  @Mutation
  setMinMaxTravelerErr(minMaxTravelerErr: boolean) {
    this.minMaxTravelerErr = minMaxTravelerErr;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setLoadingBooking(data: boolean) {
    this.loadingBooking = data;
  }

  @Mutation
  setProductNames(data: any) {
    this.productNames = data;
  }

  @Mutation
  setSelectedProductName(data: any) {
    this.selectedProductName = data;
  }

  @Mutation
  setSelectedSupplier(supplierData: Supplier | null) {
    this.selectedSupplier = supplierData;
  }

  @Mutation
  setProductsList(productsList: any) {
    this.productsList = productsList;
  }

  @Mutation
  setSelectedProductsList(data: any) {
    this.selectedProductsList = data;
  }

  @Mutation
  setRatesList(rates: any) {
    this.ratesList = rates;
  }

  @Mutation
  setSelectedProductID(productId: string) {
    this.selectedProductID = productId;
  }

  @Mutation
  setSelectedDate(date: any) {
    this.selectedDate = date;
  }

  @Mutation
  setPricingCardData(data: any) {
    this.pricingCardData = data;
  }

  @Mutation
  setPricingInfoData(data: any) {
    this.pricingInfoData = data;
  }

  @Mutation
  setSelectedCardVal(data: any) {
    this.selectedCardVal = data;
  }

  @Mutation
  setSelectedPriceSchedule(data: any) {
    this.selectedPriceSchedule = data;
  }

  @Mutation
  setPricingTotal(data: any) {
    this.pricingTotal = data;
  }

  @Mutation
  setSelectedProductStart(data: any) {
    this.selectedProductStart = data;
  }

  @Mutation
  setSelectedProductEnd(data: any) {
    this.selectedProductEnd = data;
  }

  @Mutation
  setAvailabilityRS(data: any) {
    this.availabilityRS = data;
  }

  @Mutation
  setSelectedRateID(data: any) {
    this.selectedRateID = data;
  }

  @Mutation
  setHoldRS(data: AcquireHoldRS) {
    this.holdRS = data;
  }

  @Mutation
  setItemsList(data: any) {
    this.itemsList = data;
  }

  @Mutation
  setTicketTypes(data: any) {
    this.ticketTypes = data;
  }

  @Mutation
  setAvailabilityError(data: any) {
    this.availabilityError = data;
  }

  @Mutation
  setCustomerData(data: any) {
    if (data && Object.keys(data)?.length) {
      this.customerData = data;
    } else {
      this.customerData = {};
    }
  }

  @Mutation
  setTravelersData(data: GuestDetail[]) {
    this.travelersData = data;
  }

  @Mutation
  setSelectedTicketType(data: any) {
    this.selectedTicketType = data;
  }

  @Mutation
  setSelectedTicketCategory(data: any) {
    this.selectedTicketCategory = data;
  }

  @Mutation
  setTicketCategory(data: any) {
    this.ticketCategory = data;
  }

  @Mutation
  setCurrency(data: any) {
    this.currency = data;
  }

  @Mutation
  setIncludePricing(data: any) {
    this.includePricing = data;
  }

  @Mutation
  setAgentFacing(data: any) {
    this.agentFacing = data;
  }

  @Mutation
  setBulkTickets(data: boolean) {
    this.bulkTickets = data;
  }

  @Mutation
  setBulkTicketDeliveryType(data: string) {
    this.bulkTicketDeliveryType = data;
  }

  @Mutation
  setBulkTicketDeliveryInstructions(data: string) {
    this.bulkTicketDeliveryInstructions = data;
  }

  @Mutation
  setBulkTicketDeliveryDate(data: string) {
    this.bulkTicketDeliveryDate = data;
  }

  @Mutation
  setBookingID(data: string) {
    this.bookingID = data;
  }

  @Mutation
  setBooking(data: any) {
    this.booking = data;
  }

  @Mutation
  setMarketRegions(data: any) {
    data.unshift({
      name: "All",
      value: "All",
    });
    this.marketRegions = data;
  }

  @Mutation
  setDiscountGroups(data: any) {
    data.unshift({
      name: "All",
      value: "All",
    });
    this.discountGroups = data;
  }

  @Mutation
  setSelectedMarketRegions(data: any) {
    this.selectedMarketRegions = data;
  }

  @Mutation
  setSelectedDiscountGroups(data: any) {
    this.selectedDiscountGroups = data;
  }

  @Mutation
  setSelectedRate(data: any) {
    this.selectedRate = data;

    // extract the availability data from the selected rate and set it
    const availSlots = [] as Array<AvailabilityTimeSlot>;
    this.selectedRate?.avData?.forEach((avail: any) => {
      const slot = new AvailabilityTimeSlot();
      slot.id = avail.id;
      slot.start = avail.start;
      slot.end = avail.end;
      slot.capacity = avail.capacity;
      slot.localTimezone =
        this.selectedRate && this.selectedRate.hours && this.selectedRate?.hours[0]?.timezone ? this.selectedRate?.hours[0]?.timezone : "UTC";
      availSlots.push(slot);
    });
    availSlots.sort((a, b) => moment.tz(a.start, a.localTimezone).valueOf() - moment.tz(b.start, b.localTimezone).valueOf());
    this.selectedOptionAvailabilityTimeSlots = availSlots;
  }

  @Mutation
  setSelectedProduct(data: Product) {
    this.selectedProduct = data;
  }

  @Mutation
  setTravelerTypes(data: any) {
    this.travelerTypes = data;
  }

  @Mutation
  setSelectedPolicyDescription(data: any) {
    this.selectedPolicyDescription = data;
  }

  @Mutation
  setAllowedDates(dates: Array<string>) {
    this.allowedDates = dates;
  }

  /* Actions */
  @Action
  startLoading() {
    this.setLoading(true);
  }

  @Action
  loadTravelerTypes() {
    const ps = this.SelectedRate.priceSchedule || this.SelectedRate.prices;
    const travelerTypes = ps.map((el: any) => el.travelerType.ageBand).sort();
    const ageRanges = ps.map((el: any) => {
      return { type: el.travelerType.ageBand, minAge: el.travelerType.minAge, maxAge: el.travelerType.maxAge };
    });
    this.setTravelerTypes(travelerTypes);
    this.setAgeRanges(ageRanges);
  }

  @Action
  async getDeactiveTickets() {
    await resellerClient
      .listDeactiveTickets({
        supplierId: this.SelectedSupplierID,
        channels: this.ChannelID,
      })
      .toPromise()
      .then((disabledTickets) => {
        if (disabledTickets) {
          this.setDeactiveTickets(disabledTickets);
        }
      });
  }

  @Action
  async getAllowedDates(date: string) {
    if (!this.SelectedSupplierID) {
      return;
    }
    await Promise.resolve(this.setLoading(true))
      .then(() =>
        productsClient.getProductsAllByMonth({
          supplierId: this.SelectedSupplierID,
          startDate: date,
          endDate: date,
        }),
      )
      .then((productsList) => {
        this.setAllowedDates(productsList);
      })
      .catch(() => {
        appModule.addMessageErr(codes.PRODUCTS_ERROR_LOAD);
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  async getProductsListAll() {
    if (!this.SelectedSupplierID) {
      appModule.addMessageErr(codes.PRODUCTS_ERROR_RQ);
      return;
    }

    await Promise.resolve(this.setLoading(true))
      .then(() =>
        productsClient.getProductsAll({
          supplierId: this.SelectedSupplierID,
          startDate: this.selectedDate,
          endDate: this.selectedDate,
        }),
      )
      .then(async (productsList) => {
        const sortedProductsList = productsList.sort(function (a: any, b: any) {
          const start = a.name.toLowerCase().charCodeAt();
          const end = b.name.toLowerCase().charCodeAt();
          return start - end;
        });
        sortedProductsList.forEach((product: any) => {
          product.rates = product.rates.map((rate: any) => {
            if (product?.extensions && product.extensions?.[EXT_BETA_AVAILABILITY_PRICING_TYPE] !== undefined) {
              rate.availabilityPricingType = product.extensions[EXT_BETA_AVAILABILITY_PRICING_TYPE];
            }
            return rate;
          });
        });
        this.setProductsList(sortedProductsList);
        this.setSelectedProducts();
        await this.setSelectedRates();
      })
      .catch(() => {
        appModule.addMessageErr(codes.PRODUCTS_ERROR_LOAD);
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  setSelectedProducts() {
    this.setSelectedProductsList(this.ProductsList);
    const productNames = this.ProductsList.map((item: any) => {
      return { text: item.name, value: item.id, octoId: item.octoId };
    });
    productNames.unshift({ text: "All", value: "all", octoId: "0" });
    this.setProductNames(productNames);
    this.setSelectedProductName("all");
  }

  @Action
  async setSelectedRates() {
    const allRates = _.flatten(this.ProductsList.map((item) => item.rates));
    const availableRatesLS = allRates
      .filter((rate: any) => {
        return this.DeactiveTickets.find((ticket) => ticket.rateId === rate.id) === undefined;
      })
      .map((rate: any) => {
        if (rate.priceSchedule && Array.isArray(rate.priceSchedule)) {
          // flag if there is retail prices for all entries
          rate.hasRetailPricing = allPricesHaveRetail(rate.priceSchedule);

          // for each price generate display data
          rate.priceSchedule.forEach((price: any) => {
            const ttName = price?.travelerType?.name?.toString().trim() || "";
            const ttAgeBand = price?.travelerType?.ageBand?.toString().trim() || "";
            const ttDispplay = constants.travlerDisplayNames[ttAgeBand as keyof typeof constants.travlerDisplayNames];

            if (ttName === ttAgeBand || ttName.toUpperCase().includes(ttAgeBand.toUpperCase())) {
              price.priceName = ttName;
            } else {
              price.priceName = `${ttName} (${ttAgeBand})`;
            }
            price.ttDispplay = ttDispplay.toLowerCase();
          });
        }

        // set if we have availability data or not
        const hasAvailability = rate.avData?.some((availability: any) => parseInt(availability.capacity) > 0);
        rate.hasAvailability = hasAvailability;
        return rate;
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name); // sort by name
      });

    if (availableRatesLS?.length) {
      this.setRatesList(availableRatesLS);
      this.setAvailableProducts();
    } else {
      this.setAvailabilityError(true);
    }

    const marketRegions = _.uniq(_.compact(allRates.map((item: any) => item?.ext?.[EXT_DISNEY_MARKET_REGIONS])));
    if (marketRegions && marketRegions?.length) {
      const tempMktRegions = mapMarketRegions(marketRegions);
      this.setMarketRegions(tempMktRegions);
    }

    const discountGroups = _.uniq(_.compact(allRates.map((item: any) => item?.ext?.[EXT_DISNEY_DISCOUNT_GROUPS])));
    if (discountGroups && discountGroups?.length) {
      const tempDiscountGroups = mapDiscountGroups(discountGroups);
      this.setDiscountGroups(tempDiscountGroups);
    }
  }

  @Action
  setAvailableProducts() {
    this.setSelectedProductsList(this.ProductsList);
    const allRates = this.RatesList;
    const availableProducts: any = [];

    this.ProductsList.forEach(function (element: any) {
      const rates = element.rates || [];
      const allRatesFilter = allRates?.map((itm: any) => (itm ? itm?.id : "")).filter((id) => id != "");

      const check = rates
        ? rates.filter((item: any) => {
            return allRatesFilter.indexOf(item.id) > -1;
          })
        : [];

      if (check && check?.length) {
        availableProducts.push(element);
      }
    });

    const productNames = availableProducts.map((item: any) => {
      return { text: item.name, value: item.id, octoId: item.octoId };
    });

    productNames.unshift({ text: "All", value: "all", octoId: "0" });
    this.setProductNames(productNames);
    this.setSelectedProductName("all");
  }

  @Action
  loadSelectedProducts() {
    let rateLS: any = [];

    if (this.SelectedProductName === "all") {
      rateLS = _.flatten(this.ProductsList.map((item) => item.rates));
    } else {
      const selectedProd = this.ProductsList.find((element) => {
        return element.id === this.SelectedProductName;
      });
      rateLS = selectedProd?.rates ? selectedProd?.rates : [];
    }

    const availableRates = rateLS.filter((rate: any) => this.DeactiveTickets.find((ticket) => ticket.rateId === rate.id) === undefined);
    this.setRatesList(availableRates);
    this.applyFilters();
  }

  @Action
  applyFilters() {
    let rateLS = this.RatesList;

    if (this.SelectedMarketRegions != "All") {
      rateLS = rateLS.filter((item: any) => {
        return item?.ext?.[EXT_DISNEY_MARKET_REGIONS] === this.SelectedMarketRegions;
      });
    }

    if (this.SelectedDiscountGroups != "All") {
      rateLS = rateLS.filter((item: any) => {
        return item?.ext?.[EXT_DISNEY_DISCOUNT_GROUPS] === this.SelectedDiscountGroups;
      });
    }

    this.setRatesList(rateLS);
  }

  @Action
  async checkAvailability() {
    if (this.isCartEmpty) {
      this.setAvailabilityError(true);
      appModule.addMessageErr(codes.CART_EMPTY_ERROR);
      return false;
    }

    this.setLoading(true);

    try {
      // FIXME: this will need to be updated later to iterate through each cart item for now just use the first
      const totalTravelers = this.getTotalTravelersInCart;
      const firstCartItem = this.getCart[0];
      const availabilityRS = await availabilityClient.getSpecificAvailabilityRS({
        supplierId: firstCartItem.supplierID,
        productId: firstCartItem.productID,
        rateId: firstCartItem.rateID,
        at: firstCartItem.startTime,
        qty: totalTravelers,
      });

      if (availabilityRS?.length) {
        let checkUnAvailability = [];
        const isBookable = availabilityRS[0].bookable || false;
        if (!isBookable) {
          this.setAvailabilityError(true);
          appModule.addPermanentErrorMsg("Product is no longer available.");
          return false;
        }

        checkUnAvailability = availabilityRS.filter((element: any) => {
          const capacity = element?.capacity || 0;
          return element?.id && parseInt(capacity) <= totalTravelers;
        });

        if (!availabilityRS.length || checkUnAvailability.length) {
          this.setAvailabilityError(true);
          appModule.addPermanentErrorMsg("Product is no longer available.");
          return false;
        } else {
          this.setAvailabilityRS(availabilityRS);
          this.setAvailabilityError(false);
          return true;
        }
      } else {
        this.setAvailabilityError(true);
        appModule.addPermanentErrorMsg("Product is no longer available.");
        return false;
      }
    } catch (error: any) {
      this.setAvailabilityError(true);
      appModule.addPermanentErrorMsg(`Product is no longer available.<br/>${error.message}`);
      return false;
    } finally {
      this.setLoading(false);
    }
  }

  get constructItemExtensionMap(): any {
    const ext = {} as any;
    if (this.IsDisney && this.SelectedRate && this.SelectedRate.ext && this.SelectedRate?.ext[EXT_DISNEY_PARK_RESERVATIONS_REQUIRED] === "true")
      ext[EXT_DISNEY_PARK_RESERVATIONS_REQUIRED] = this.SelectedRate.ext[EXT_DISNEY_PARK_RESERVATIONS_REQUIRED];
    if (this.SelectedProductName && this.SelectedParkDays.length) {
      this.SelectedParks.map((park: any) => {
        const p = constants.parks.find((item: any) => {
          return item.name.includes(park.name);
        });
        ext[`${EXT_DISNEY_PARK_RESERVATION_DATE}_${park.date}`] = p?.code;
      });
    }
    /*pickup ext rewrite to internal names*/
    if (this.HoldRS.hold?.ext && this.HoldRS.hold.ext[EXT_GRAND_CANYON_GROUP_HOTEL_NAME]) {
      ext[EXT_RESELLER_PORTAL_PICKUP_LOCATION_NAME] = this.HoldRS.hold.ext[EXT_GRAND_CANYON_GROUP_HOTEL_NAME];
    }
    if (this.HoldRS.hold?.ext && this.HoldRS.hold.ext[EXT_GRAND_CANYON_GROUP_PICKUP_LOCATION]) {
      ext[EXT_RESELLER_PORTAL_PICKUP_LOCATION_ADDRESS] = this.HoldRS.hold.ext[EXT_GRAND_CANYON_GROUP_PICKUP_LOCATION];
    }
    if (this.HoldRS.hold?.ext && this.HoldRS.hold.ext[EXT_GRAND_CANYON_GROUP_TIME_LOCAL]) {
      ext[EXT_RESELLER_PORTAL_PICKUP_LOCATION_TIME] = this.HoldRS.hold.ext[EXT_GRAND_CANYON_GROUP_TIME_LOCAL];
    }
    return ext;
  }

  /**
   * acquireHold - creates the hold request, sends it and processes the results
   */
  @Action
  // for acquireHold create payload: CheckoutSessionRQ param when stripe enabled RE-8144
  // payload: CheckoutSessionRQ
  async acquireHold() {
    if (this.isCartEmpty) {
      appModule.addMessageErr(codes.ACQUIRE_HOLD_ERROR_RQ);
      return Promise.reject(codes.ACQUIRE_HOLD_ERROR_RQ);
    }

    // if the rate is not holdable, skip to the booking
    if (!this.SelectedRate.holdable) {
      //remove this.createBooking and uncomment this.initStripeEmbeddedCheckout when stripe is enabled RE-8144
      return this.createBooking();
      // await this.initStripeEmbeddedCheckout(payload);
    }

    await Promise.resolve(this.setLoading(true))
      .then(() => this.setLoadingBooking(true))
      .then(async () => {
        // construct extension map data
        const ext = this.constructItemExtensionMap;

        // for each cart item create the hold item
        const holdItems: HoldItem[] = [] as Array<HoldItem>;
        let holdItem = {} as HoldItem;
        this.cart.forEach((cartItem: CartItem) => {
          holdItem = cartItem.mapToHoldItem();
          holdItem.ext = { ...holdItem.ext, ...ext };
          holdItems.push(holdItem);
        });
        const holdReq = {
          hold: {
            items: holdItems,
          },
        } as AcquireHoldRQ;
        if (this.IsGrandCanyonGroup) {
          this.TravelersData.map((traveler: GuestDetail) => {
            if (traveler.isLead && traveler.pickupLoc && holdReq.hold) {
              holdReq.hold.ext = {
                [EXT_GRAND_CANYON_GROUP_HOTEL_ID]: traveler.pickupLoc.id,
              };
            }
          });
        }
        return holdClient
          .acquireHoldRS(holdReq)
          .catch((e) => {
            appModule.addPermanentErrorMsg(`Failed to hold booking.<br/>${e.message}`);
            throw e;
          })
          .then((holdRS) => {
            this.setHoldRS(holdRS);
            //remove this.createBooking and uncomment this.initStripeEmbeddedCheckout when stripe is enabled RE-8144
            // make callback async to handle await
            // await this.initStripeEmbeddedCheckout(payload);
            return this.createBooking();
          });
      })
      .finally(() => {
        this.setLoading(false);
        this.setLoadingBooking(false);
      });
  }

  @Action
  async initStripeEmbeddedCheckout(payload: CheckoutSessionRQ) {
    try {
      await httpModule.createCheckoutSession({
        amount: payload.amount,
        currency: payload.currency,
        productName: payload.productName,
        successCallback: payload.successCallback,
      });
      if (stripeModule.ClientSecret.length) {
        const stripe = await loadStripe(config.StripePublishableKey);
        const checkout = await stripe?.initEmbeddedCheckout({
          clientSecret: stripeModule.ClientSecret,
          onComplete: async () => {
            await httpModule.retrieveCheckoutSession(stripeModule.SessionID);
            if (stripeModule.SessionStatus.status !== constants.CHECKOUT_SESSION_COMPLETE) return;
            await this.createBooking();
            if (checkout) checkout.destroy();
            payload.successCallback();
          },
        });
        if (checkout) {
          stripeModule.setCheckout(checkout);
          checkout.mount("#checkout");
        }
      }
    } catch (e) {
      //todo error handler
      console.log(e);
    }
  }

  /**
   * createBooking - creates the booking request, sends it and handles the response
   */
  @Action
  async createBooking() {
    await Promise.resolve(this.setLoading(true))
      .then(() => this.setLoadingBooking(true))
      .then(() => {
        const customerInfo: Customer = this.CustomerData;
        const phoneNumber = getCompanyContact(this.SelectedPOS);
        this.setCompanyPhoneNumber(phoneNumber);

        // construct extension map data
        const ext = this.constructItemExtensionMap;

        // for each cart item create the booking item
        const bookingItems: Item[] = [] as Array<Item>;
        let holdItem = {} as Item;
        this.cart.forEach((cartItem: CartItem) => {
          holdItem = cartItem.mapToBookingItem();
          bookingItems.push(holdItem);
        });
        bookingItems.forEach((itm: Item, idx) => {
          let travelerInfo = {} as Traveler;
          const traveler = this.TravelersData.find((traveler: GuestDetail) => traveler.id === itm.id);
          travelerInfo = ModuleBookingAdd.buildTraveler(
            traveler && this.IsGrandCanyonGroup
              ? {
                  firstName: traveler.firstName,
                  lastName: traveler.lastName,
                  email: traveler.email?.length ? traveler.email : customerInfo.email,
                  phone: traveler.phone,
                }
              : customerInfo,
            itm.traveler?.type ? itm.traveler.type : "ADULT",
            true,
            itm.ext,
          );
          if (idx > 0) travelerInfo.isLead = false;
          itm["traveler"] = travelerInfo;
        });

        const bookingExtensions = this.constructBookingExtensions;

        // construct the booking request
        const bookingReq: BookingRQ = {
          booking: {
            customer: customerInfo,
            items: bookingItems,
            holdId: this.holdRS?.hold?.id,
            resellerBookingRef: customerInfo.clientRef,
            ext: {
              ...bookingExtensions,
              ...ext,
            },
          },
        };
        return bookingReq;
      })
      .then((bookingReq) =>
        bookingClient
          .createBooking(bookingReq)
          .toPromise()
          .then((rs) => rs.booking)
          .then((booking) => {
            const bookingID = booking?.id || "";
            this.setBookingID(bookingID);
            this.setBooking(booking);
            if (this.IsDisney) {
              this.setSelectedParkDays([]);
              this.sendDisneyBookingConfirmationForAgent();
              this.sendDisneyBookingConfirmationForCustomer();
              this.sendDisneyTicketConfirmationForAgent();
              this.sendDisneyTicketConfirmationForCustomer();
            } else {
              this.sendBookingConfirmationForAgent();
              this.sendBookingConfirmationForCustomer();
            }
          }),
      )
      .catch(async (error) => {
        appModule.addPermanentErrorMsg(`Failed to complete booking.<br/>${error.message}`);
        if (this.SelectedRate.holdable && this.holdRS?.hold?.id) {
          await holdClient.releaseHoldRS(this.holdRS?.hold?.id);
        }
        throw error;
      })
      .finally(() => {
        this.setLoading(false);
        this.setLoadingBooking(false);
      });
  }

  private static buildTraveler(customerInfo: any, type: string, lead: boolean, ext?: Ext3): Traveler {
    return {
      firstName: customerInfo.firstName,
      isLead: lead,
      lastName: customerInfo.lastName,
      type: type.toUpperCase(),
      country: "",
      email: customerInfo.email,
      gender: "",
      lang: "",
      phone: customerInfo.phone,
      extensions: ext,
    };
  }

  private static setIsLead(items: Item[], customerInfo: any, isLeadSet: boolean, tt: string): boolean {
    items
      .filter((item: any) => item.traveler?.type === tt)
      .forEach((itm: any, idx) => {
        const isLead = !isLeadSet;
        if (isLead) isLeadSet = true;
        const travelerInfo = ModuleBookingAdd.buildTraveler(customerInfo, tt, isLead);
        if (idx > 0) travelerInfo.isLead = false;
        itm["traveler"] = travelerInfo;
      });

    return isLeadSet;
  }

  /**
   * constructBookingExtensions - constructs the top level booking extension map
   */
  get constructBookingExtensions(): any {
    const ext = {} as any;
    ext[EXT_RESELLER_PORTAL_AGENT_NAME] = profileModule.Profile ? profileModule.Profile.given_name + " " + profileModule.Profile.surname : "";
    ext[EXT_RESELLER_PORTAL_AGENT_EMAIL] = profileModule.Profile?.email;
    ext[EXT_RESELLER_PORTAL_RESELLER_NAME] = this.ResellerData ? this.ResellerData.name : "Reseller Portal";

    // Add Disney extensions
    if (this.isDisney) {
      ext[EXT_RESELLER_PORTAL_RESELLER_REGION] = this.getSelectedResellerRegion;

      // Disney Bulk Tickets
      if (this.bulkTickets) {
        ext[EXT_DISNEY_BULK_TICKET_PURCHASE] = "true";
        if (this.bulkTicketDeliveryType != "") {
          ext[EXT_DISNEY_BULK_TICKET_DELIVERY_TYPE] = this.bulkTicketDeliveryType;
        }
        if (this.bulkTicketDeliveryInstructions != "") {
          ext[EXT_DISNEY_BULK_TICKET_DELIVERY_INSTRUCTIONS] = this.bulkTicketDeliveryInstructions;
        }
        if (this.bulkTicketDeliveryDate != "") {
          ext[EXT_DISNEY_BULK_TICKET_DELIVERY_DATE] = this.bulkTicketDeliveryDate;
        }
        this.logger.info("Disney Bulk Ticket Purchase", {
          bulkTicketDeliveryType: this.bulkTicketDeliveryType,
          bulkTicketDeliveryInstructions: this.bulkTicketDeliveryInstructions,
          bulkTicketDeliveryDate: this.bulkTicketDeliveryDate,
          totalTickets: this.getTotalTravelersInCart,
          clientRef: this.CustomerData.clientRef || "",
          agent: profileModule.Profile?.email || "unknown",
        });
      }
    }

    return ext;
  }

  @Action
  updatePricingInfo(priceIDToTicketCountMap: Map<string, number>) {
    this.setPricingInfoData([]); //RESET PRICING INFO DATA

    // if map is null or empty then we are done
    if (!priceIDToTicketCountMap || priceIDToTicketCountMap.size === 0) {
      return;
    }

    // initialize vars
    const priceSchedule = this.getTimeFilteredPriceSchedule;
    let totalPrice = 0;

    // calculate total travelers
    let totalTravelers = 0;
    for (const [, value] of priceIDToTicketCountMap.entries()) {
      if (value > 0) {
        totalTravelers += value;
      }
    }

    // check min/max traveler counts
    this.setMinMaxTravelerErr(false);
    const minTravelers = this.SelectedRate.minTravelers || 1;
    const maxTravelers = this.SelectedRate.maxTravelers || undefined;
    if (totalTravelers < minTravelers) {
      this.setMinMaxTravelerErr(true);
    }
    if (maxTravelers && totalTravelers > maxTravelers) {
      this.setMinMaxTravelerErr(true);
    }

    if (totalTravelers >= 0) {
      if (priceSchedule && Array.isArray(priceSchedule)) {
        priceSchedule.forEach((ps: any) => {
          if (ps == null) {
            return;
          }

          // Note: the UI displays the per-ticket prices and then in the total shows the total (ticket * qty)
          const ticketCount = priceIDToTicketCountMap.get(ps.id) || 0;
          if (ticketCount > 0) {
            let perTicketPrice = 0;
            if (ps.pricePerTicket != null) {
              perTicketPrice = ps.pricePerTicket;
            }
            let perTicketTax = 0;
            if (ps.includedTaxes) {
              for (const tax of ps.includedTaxes) {
                if (tax.retail != null) {
                  perTicketTax += tax.retail;
                }
              }
            }
            const perTicketTaxExclusivePrice = perTicketPrice - perTicketTax;
            const totalTicketsPrice = perTicketPrice * ticketCount;

            const priceInfo: PricingInfoData = {
              priceID: ps.id,
              name: ps.name || ps.type,
              type: _.capitalize(ps.type),
              ageBand: ps.ageBand,
              unitId: ps.unitId,
              pricePerTicket: perTicketTaxExclusivePrice,
              taxesFees: perTicketTax,
              currency: ps.currency || "USD",
              noOfTravellers: ticketCount,
              total: totalTicketsPrice,
              priceName: "",
            };

            const ttName = ps?.travelerType.name?.toString().trim() || "";
            const ttAgeBand = ps?.travelerType.ageBand?.toString().trim() || "";
            priceInfo.priceName = ModuleBookingAdd.buildPriceName(ttName, ttAgeBand);
            this.pricingInfoData.push(priceInfo);
            this.setCurrency(ps?.currency);
            totalPrice += totalTicketsPrice;
          }
        });
      }

      if (totalPrice > 0) {
        this.setPricingTotal(totalPrice);
      } else {
        this.setPricingTotal("0");
      }
    } else {
      this.setPricingTotal("0");
    }

    // FIXME: new cart code here; the above code will be removed at some point and replaced with the cart code below
    // FIXME: however this is more of a change than we want to tackle right now...
    this.clearCart();
    const cartItems: CartItem[] = [] as Array<CartItem>;
    for (const [key, value] of priceIDToTicketCountMap.entries()) {
      if (value == 0) {
        continue;
      }
      if (!ItemPerTraveler(posModule.SelectedSupplierID)) {
        const cartItem = new CartItem();
        cartItem.supplierID = this.SelectedSupplierID;
        cartItem.productID = this.selectedProductID;
        cartItem.rateID = this.selectedRateID;
        cartItem.priceID = key;
        cartItem.startTime = this.getUserSelectedAvailabilityTimeSlot.start;
        cartItem.availabilityID = this.getUserSelectedAvailabilityTimeSlot.id;
        cartItem.quantity = value;

        // get the traveler type
        this.getTimeFilteredPriceSchedule.forEach((psItem: any) => {
          if (psItem.id === key) {
            cartItem.travelerType = psItem.travelerType.ageBand;
          }
        });
        // push the item to the cart
        cartItems.push(cartItem);
      } else {
        const travelers = this.TravelersData.filter((guest: GuestDetail) => guest.travelerId === key) || [];
        travelers.map((traveler: GuestDetail, idx: number) => {
          traveler.isLead = idx <= 0;
          const cartItem = new CartItem();
          cartItem.supplierID = this.SelectedSupplierID;
          cartItem.id = traveler.id;
          cartItem.productID = this.selectedProductID;
          cartItem.rateID = this.selectedRateID;
          cartItem.priceID = key;
          cartItem.startTime = this.getUserSelectedAvailabilityTimeSlot.start;
          cartItem.availabilityID = this.getUserSelectedAvailabilityTimeSlot.id;
          cartItem.quantity = 1;
          if (traveler.weight && traveler.weight.length) {
            cartItem.ext = {
              [EXT_BETA_TRAVELER_WEIGHT]: `${traveler.weight} ${traveler.weightType}`,
            };
          }

          // get the traveler type
          this.getTimeFilteredPriceSchedule.forEach((psItem: any) => {
            if (psItem.id === key) {
              cartItem.travelerType = psItem.travelerType.ageBand;
            }
          });
          // push the item to the cart
          cartItems.push(cartItem);
        });
      }
    }

    this.setCart(cartItems);
  }

  private static buildPriceName(ttName: string, ttAgeBand: string): string {
    if (ttName === ttAgeBand || ttName.toUpperCase().includes(ttAgeBand.toUpperCase())) {
      return ttName;
    }
    return `${ttName} (${ttAgeBand})`;
  }

  /**
   * sendBookingConfirmationEmail - sends the generic booking confirmation email
   * @param params
   */
  @Action
  async sendBookingConfirmationEmail(params: EmailConfirmationParams) {
    // parameters
    const emailTemplate = params.emailTemplate;
    const bookingID = params.bookingID;
    const skipEmail = params.skipEmail;
    const sendToAgent = params.sendToAgent;
    const sendToCustomer = params.sendToCustomer;
    const withNetPrices = params.withNetPrices;
    const withRetailPrices = params.withRetailPrices;

    // gather agent details
    const user = profileModule.Profile;
    const agentEmail = user?.email || "";
    const agentName = user ? user.given_name + " " + user.surname : "";
    const resellerName = this.ResellerData ? this.ResellerData.name : "Reseller Portal";
    const contactPhone = this.CompanyPhoneNumber;

    // log it
    this.logger.info(`email notification selections for booking ${bookingID}`, {
      agentEmail: agentEmail,
      agentName: agentName,
      reseller: resellerName,
      bookingID: bookingID,
      emailTemplate: emailTemplate,
      skipEmail: skipEmail,
      sendToAgent: skipEmail ? false : sendToAgent,
      sendToCustomer: skipEmail ? false : sendToCustomer,
      withNetPrices: withNetPrices,
      withRetailPrices: withRetailPrices,
    });

    // if we are skipping the email then bail
    if (skipEmail) return;

    // send it
    try {
      const req: VoucherPDFRQ = {
        bookingID: bookingID,
        templateID: emailTemplate,
        format: "email",
        emailOverrides: sendToAgent ? [agentEmail] : [],
        parameters: {
          ContactPhone: contactPhone,
          ResellerName: resellerName,
          AgentFacing: sendToAgent,
          WithRetailPricing: withRetailPrices,
          WithNetPricing: withNetPrices,
          WithOriginalPricing: false,
          AgentName: agentName,
        },
      };
      const voucherEmailRS = await voucherClient.sendVoucherEmail(req);
      if (voucherEmailRS) appModule.addMessageSuccess(codes.BOOKING_CONFIRMATION_SUCCESS);
    } catch (error: any) {
      const errorMsg = error?.response?.statusText ? error?.response?.statusText : "Error";
      appModule.addErrorMsg(errorMsg);
    }
  }

  /**
   * sendBookingConfirmationForAgent - sends the agent facing booking confirmation
   */
  @Action
  async sendBookingConfirmationForAgent() {
    // if we don't have a bookingID bail
    const bookingID = this.BookingID;
    if (!bookingID) return;

    // construct params
    const emailParams: EmailConfirmationParams = {
      emailTemplate: "generic_booking_confirmation",
      bookingID: bookingID,
      skipEmail: this.AgentFacing === "none",
      sendToAgent: true,
      sendToCustomer: false,
      withNetPrices: this.AgentFacing === "true",
      withRetailPrices: this.AgentFacing === "true" || this.AgentFacing === "false",
    };

    // send it
    return this.sendBookingConfirmationEmail(emailParams);
  }

  /**
   * sendBookingConfirmationForCustomer - sends the customer facing booking confirmation
   */
  @Action
  async sendBookingConfirmationForCustomer() {
    // if we don't have a bookingID bail
    const bookingID = this.BookingID;
    if (!bookingID) return;

    // construct params
    const emailParams: EmailConfirmationParams = {
      emailTemplate: "generic_booking_confirmation",
      bookingID: bookingID,
      skipEmail: this.IncludePricing === "none",
      sendToAgent: false,
      sendToCustomer: true,
      withNetPrices: false,
      withRetailPrices: this.IncludePricing === "true",
    };

    // send it
    return this.sendBookingConfirmationEmail(emailParams);
  }

  /**
   * sendDisneyConfirmationEmail - sends the Disney booking confirmation email
   * @param params
   */
  @Action
  async sendDisneyConfirmationEmail(params: EmailConfirmationParams) {
    // parameters
    const emailTemplate = params.emailTemplate;
    const bookingID = params.bookingID;
    const skipEmail = params.skipEmail;
    const sendToAgent = params.sendToAgent;
    const sendToCustomer = params.sendToCustomer;
    const withNetPrices = params.withNetPrices;
    const withRetailPrices = params.withRetailPrices;

    // gather agent details
    const user = profileModule.Profile;
    const agentEmail = user?.email || "";
    const agentName = user ? user.given_name + " " + user.surname : "";
    const resellerName = this.ResellerData ? this.ResellerData.name : "Reseller Portal";
    const contactPhone = this.CompanyPhoneNumber;

    // log it
    this.logger.info(`Disney email notification selections for booking ${bookingID}`, {
      agentEmail: agentEmail,
      agentName: agentName,
      reseller: resellerName,
      bookingID: bookingID,
      emailTemplate: emailTemplate,
      skipEmail: skipEmail,
      sendToAgent: skipEmail ? false : sendToAgent,
      sendToCustomer: skipEmail ? false : sendToCustomer,
      withNetPrices: withNetPrices,
      withRetailPrices: withRetailPrices,
    });

    // if we are skipping the email then bail
    if (skipEmail) return;

    // gather Disney specific data
    const ticketStartDateExt = getDisneyTicketStartDate(this.Booking.ext);
    const ticketEndDateExt = getDisneyTicketEndDate(this.Booking.ext);
    const dateStart = ticketStartDateExt ? formatMDY(ticketStartDateExt) : "";
    const dateEnd = ticketEndDateExt ? formatMDY(ticketEndDateExt) : "";
    const dateBased = this.Booking.ext[EXT_DISNEY_DATE_BASED_TICKET] === "true";
    const marketRegion = this.SelectedRate && this.SelectedRate?.ext ? this.SelectedRate?.ext[EXT_DISNEY_MARKET_REGIONS] : "";
    const discountGroup = this.SelectedRate && this.SelectedRate?.ext ? this.SelectedRate?.ext[EXT_DISNEY_DISCOUNT_GROUPS] : "";
    const productDuration = this.SelectedRate?.ext?.[EXT_DISNEY_PRODUCT_DURATION] ? this.SelectedRate?.ext?.[EXT_DISNEY_PRODUCT_DURATION] : "";
    const bufferDays =
      this.SelectedRate && this.SelectedRate?.ext && this.SelectedRate?.ext[EXT_DISNEY_BOOKING_BUFFER_DAYS]
        ? parseInt(this.SelectedRate?.ext[EXT_DISNEY_BOOKING_BUFFER_DAYS])
        : 0;

    const calcData = calculateTicketValidityDays(productDuration, bufferDays, this.selectedDate, true, this.SelectedSupplier, this.Booking);
    const pickupInstruction = getPickupInstruction(this.SelectedRate?.ext);

    // send it
    try {
      const req: VoucherPDFRQ = {
        bookingID: bookingID,
        templateID: emailTemplate,
        format: "email",
        emailOverrides: sendToAgent ? [agentEmail] : [],
        parameters: {
          ContactPhone: contactPhone,
          ResellerName: resellerName,
          AgentFacing: sendToAgent,
          WithRetailPricing: withRetailPrices,
          WithNetPricing: withNetPrices,
          WithOriginalPricing: false,
          AgentName: agentName,
          MarketRegion: marketRegion,
          DiscountGroup: discountGroup,
          PickupInstructions: pickupInstruction as PickupInstructions,
          ParkReservationStatus: reservationStatus(this.Booking?.ext) as ParkReservationStatus,
          ResellerRegion: this.getSelectedResellerRegion,
        },
      };
      if (dateBased) {
        req.parameters = {
          ...req.parameters,
          TicketStartDate: dateStart,
          TicketEndDate: dateEnd,
          TicketValidityDays: calcData.ticketValidityDays,
        };
      }

      const voucherEmailRS = await voucherClient.sendVoucherEmail(req);
      if (voucherEmailRS) appModule.addMessageSuccess(codes.DISNEY_CONFIRMATION_SUCCESS);
    } catch (error: any) {
      const errorMsg = error?.response?.statusText ? error?.response?.statusText : "Error";
      appModule.addErrorMsg(errorMsg);
    }
  }

  /**
   * sendDisneyBookingConfirmationForAgent - sends the Disney booking confirmation to the agent
   */
  @Action
  async sendDisneyBookingConfirmationForAgent() {
    // if we don't have a bookingID bail
    const bookingID = this.BookingID;
    if (!bookingID) return;

    // construct params
    const emailParams: EmailConfirmationParams = {
      emailTemplate: "disney_booking_confirmation",
      bookingID: bookingID,
      skipEmail: this.AgentFacing === "none",
      sendToAgent: true,
      sendToCustomer: false,
      withNetPrices: this.AgentFacing === "true",
      withRetailPrices: this.AgentFacing === "true" || this.AgentFacing === "false",
    };

    // send it
    await this.sendDisneyConfirmationEmail(emailParams);
  }

  /**
   * sendDisneyBookingConfirmationForCustomer - sends the Disney booking confirmation to the customer
   */
  @Action
  async sendDisneyBookingConfirmationForCustomer() {
    // if we don't have a bookingID bail
    const bookingID = this.BookingID;
    if (!bookingID) return;

    // construct params
    const emailParams: EmailConfirmationParams = {
      emailTemplate: "disney_booking_confirmation",
      bookingID: bookingID,
      skipEmail: this.IncludePricing === "none",
      sendToAgent: false,
      sendToCustomer: true,
      withNetPrices: false,
      withRetailPrices: this.IncludePricing === "true",
    };

    // send it
    await this.sendDisneyConfirmationEmail(emailParams);
  }

  /**
   * sendDisneyTicketConfirmationForAgent - sends the Disney ticket confirmation (will call) to the agent
   */
  @Action
  async sendDisneyTicketConfirmationForAgent() {
    // skip will call if this was a bulk ticket purchase
    if (this.bulkTickets) return;

    // if we don't have a bookingID bail
    const bookingID = this.BookingID;
    if (!bookingID) return;

    // construct params
    const emailParams: EmailConfirmationParams = {
      emailTemplate: "wdw_will_call_confirmation",
      bookingID: bookingID,
      skipEmail: this.AgentFacing === "none",
      sendToAgent: true,
      sendToCustomer: false,
      withNetPrices: false,
      withRetailPrices: false,
    };

    // send it
    await this.sendDisneyConfirmationEmail(emailParams);
  }

  /**
   * sendDisneyTicketConfirmationForCustomer - sends the Disney ticket confirmation (will call) to the customer
   */
  @Action
  async sendDisneyTicketConfirmationForCustomer() {
    // skip will call if this was a bulk ticket purchase
    if (this.bulkTickets) return;

    // if we don't have a bookingID bail
    const bookingID = this.BookingID;
    if (!bookingID) return;

    // construct params
    const emailParams: EmailConfirmationParams = {
      emailTemplate: "wdw_will_call_confirmation",
      bookingID: bookingID,
      skipEmail: this.IncludePricing === "none",
      sendToAgent: false,
      sendToCustomer: true,
      withNetPrices: false,
      withRetailPrices: false,
    };

    // send it
    await this.sendDisneyConfirmationEmail(emailParams);
  }

  @Action
  resetInfo() {
    this.setBookingID("");
    this.setIncludePricing("true");
    this.setAgentFacing("true");
    this.setPricingInfoData([]);
    this.setRatesList([]);
    this.setPricingCardData([]);
    this.setSelectedProductsList([]);
    this.setSelectedProductStart("1");
    this.setSelectedProductEnd("1");
    this.setTicketTypes([]);
    this.setTicketCategory([]);
    this.setSelectedTicketType("");
    this.setSelectedTicketCategory([]);
    this.setSelectedProductName("all");
    this.setProductNames([]);
    this.setSelectedProduct({});
    this.setMarketRegions([]);
    this.setSelectedMarketRegions("All");
    this.setDiscountGroups([]);
    this.setSelectedDiscountGroups("All");
    this.setSelectedPolicyDescription("");

    this.resetCards();
  }

  @Action
  resetCards() {
    this.setMinMaxTravelerErr(false);
    this.setItemsList([]);
    this.setSelectedCardVal("");
    this.setPricingTotal("0");
    this.setAvailabilityRS([]);
    this.setSelectedRateID("");
    this.setSelectedRate({});

    this.setSelectedPriceSchedule([]);
    this.setAvailabilityError(false);
    appModule.clearMessages();

    moduleBookingAdd.RatesList.forEach((element, index) => {
      if (Array.isArray(moduleBookingAdd.RatesList) && moduleBookingAdd.RatesList[index]) {
        moduleBookingAdd.RatesList[index].keyName = _.uniqueId(); //for radios re-render
        moduleBookingAdd.RatesList[index].isSelected = false;
      }
    });
  }

  @Action
  resetInfoAll() {
    this.setSelectedDate("");
    this.setSelectedProductID("");
    this.setProductsList([]);
    this.setDeactiveTickets([]);
    this.setAllowedDates([]);
    this.setSelectedOptionAvailabilityTimeSlots([] as Array<AvailabilityTimeSlot>);
    this.clearCart();
    this.resetInfo();
    this.resetCards();
  }

  @Action
  finishLoading() {
    this.setLoading(false);
  }

  @Action
  clearState() {
    this.setLoading(false);
  }
}

export const moduleBookingAdd = getModule(ModuleBookingAdd, store);
