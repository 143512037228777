import { PickupLocation } from "@/api/booking/GrandCanyonModels";
import { constants } from "@/utils/constants";
import { EXT_GRAND_CANYON_GROUP_LIST, EXT_GRAND_CANYON_GROUP_LISTS } from "@/api/booking/Extensions";
import { Product } from "@/api/products/ProductModels";
import { Rate } from "@/api/booking/BookingModels";
import { companyModule } from "@/store/modules/moduleCompany";

/**
 * FilterPickUpLocations
 *
 * filters product pickup locations according to rate location code
 * @prop product: Product
 * @prop rate: Rate
 */
export function FilterPickUpLocations(product?: Product, rate?: Rate): Array<PickupLocation> {
  const allLocations =
    product && product.extensions && product.extensions[EXT_GRAND_CANYON_GROUP_LISTS]?.length
      ? JSON.parse(product.extensions[EXT_GRAND_CANYON_GROUP_LISTS])
      : [];

  if (!rate?.avData?.[0]?.extensions?.[EXT_GRAND_CANYON_GROUP_LIST]?.length) return [];

  if (allLocations === null) return [];
  if (!allLocations[rate.avData[0].extensions[EXT_GRAND_CANYON_GROUP_LIST]]) return [];
  allLocations[rate.avData[0].extensions[EXT_GRAND_CANYON_GROUP_LIST]].map(
    (item: PickupLocation) => (item.displayName = `${item.label} - ${item.estimatedMinsPrior} mins prior`),
  );
  allLocations[rate.avData[0].extensions[EXT_GRAND_CANYON_GROUP_LIST]].unshift({
    displayName: constants.NO_PICKUP_REQUIRED,
    value: null,
  });
  return allLocations[rate.avData[0].extensions[EXT_GRAND_CANYON_GROUP_LIST]];
}
/**
 * ItemPerTraveler
 *
 * defines how we collect quantity of hold/booking items
 * @prop supplierID: string
 */
export function ItemPerTraveler(supplierID: string): boolean {
  return !!(
    companyModule.FeatureFlags?.grandCanyonGroupSupplierId === supplierID ||
    companyModule.FeatureFlags?.supplierRequiresGuestName.includes(supplierID) ||
    companyModule.FeatureFlags?.supplierRequiresGuestEmail.includes(supplierID) ||
    companyModule.FeatureFlags?.supplierRequiresGuestPhone.includes(supplierID) ||
    companyModule.FeatureFlags?.supplierRequiresGuestWeight.includes(supplierID)
  );
}
