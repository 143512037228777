export const config = {
  get BackplaneApiUrl() {
    if (!process.env.VUE_BACKPLANE_API_URL) return "/api";
    return process.env.VUE_BACKPLANE_API_URL;
  },
  get CompanyApiUrl() {
    if (!process.env.VUE_APP_OPP_API_URL) return "/api/companies";
    return process.env.VUE_APP_OPP_API_URL;
  },
  get BookingApiUrl() {
    if (!process.env.VUE_APP_BOOKING_API_URL) return "/api/booking/";
    return process.env.VUE_APP_BOOKING_API_URL;
  },
  get PosApiUrl() {
    if (!process.env.VUE_APP_POS_API_URL) return "/api/pos/";
    return process.env.VUE_APP_POS_API_URL;
  },
  get StripeUrl() {
    if (!process.env.VUE_APP_STRIPE_API_URL) return "/api/stripe";
    return process.env.VUE_APP_STRIPE_API_URL;
  },
  get StripePublishableKey() {
    if (!process.env.VUE_APP_STRIPE_PUBLIC_KEY)
      return "pk_test_51PlfCZLKOGlDsxQlNt2KFfqE8t2KP5j9Nl4D2ZkPaf1oHTIkWQxPzE5R95lRcnAXMcXaBLHcIXZmvKFMc3ifaTO800J5002Nsl";
    return process.env.VUE_APP_STRIPE_PUBLIC_KEY;
  },
  get PricingApiUrl() {
    if (!process.env.VUE_APP_PRICING_API_URL) return "/api/";
    return process.env.VUE_APP_PRICING_API_URL;
  },
  get ResellersApiUrl() {
    if (!process.env.VUE_APP_COMPANY_API_URL) return "/api/resellers";
    return process.env.VUE_APP_COMPANY_API_URL;
  },
  get SuppliersApiUrl() {
    if (!process.env.VUE_APP_SUPPLIERS_API_URL) return "/api/suppliers";
    return process.env.VUE_APP_SUPPLIERS_API_URL;
  },
  get ProductsApiUrl() {
    if (!process.env.VUE_APP_PRODUCTS_API_URL) return "/api/products/";
    return process.env.VUE_APP_PRODUCTS_API_URL;
  },
  get RatesApiUrl() {
    if (!process.env.VUE_APP_RATES_API_URL) return "/api/rates/";
    return process.env.VUE_APP_RATES_API_URL;
  },
  get ReportingApiUrl() {
    if (!process.env.VUE_APP_REPORTS_API_URL) return "/api/reports";
    return process.env.VUE_APP_REPORTS_API_URL;
  },
  get VoucherApiUrl() {
    if (!process.env.VUE_APP_VOUCHER_API_URL) return "/api/voucher/";
    return process.env.VUE_APP_VOUCHER_API_URL;
  },
  get MediaApiUrl() {
    if (!process.env.VUE_APP_MEDIA_API_URL) return "/api/media/";
    return process.env.VUE_APP_MEDIA_API_URL;
  },
  get LoggingApiURL() {
    if (!process.env.VUE_APP_MEDIA_API_URL) return "/api/log/";
    return process.env.VUE_APP_MEDIA_API_URL;
  },
  get PriceScheduleOffsetBooking() {
    if (!process.env.PRICE_SCHEDULE_OFFSET_BOOKING) return "1";
    return process.env.PRICE_SCHEDULE_OFFSET_BOOKING;
  },
  get PriceScheduleOffsetPricing() {
    if (!process.env.PRICE_SCHEDULE_OFFSET_PRICING) return "365";
    return process.env.PRICE_SCHEDULE_OFFSET_PRICING;
  },
  get DisneyTicketCodesChunkSize() {
    if (!process.env.DISNEY_TICKET_CODES_CHUNK_SIZE) return "10";
    return process.env.DISNEY_TICKET_CODES_CHUNK_SIZE;
  },
  get ApiTimeout() {
    if (!process.env.API_TIMEOUT) return "300000";
    return process.env.API_TIMEOUT;
  },
  get CookiesExpirationTime() {
    if (!process.env.VUE_APP_COOKIES_EXPIRATION_TIME) return "24h";
    return process.env.VUE_APP_COOKIES_EXPIRATION_TIME;
  },
  get Env() {
    return process.env.VUE_APP_ENV;
  },
  get IsProductionEnv() {
    return this.Env === "production";
  },
  get IsLocalEnv() {
    return this.Env === "local";
  },
  get Version() {
    return process.env.VUE_APP_VERSION;
  },
  get GoogleAnalyticsTagId() {
    if (!process.env.VUE_APP_GOOGLE_TAG_ID) return "G-BSL272XHGL";
    return process.env.VUE_APP_GOOGLE_TAG_ID;
  },

  Validate() {
    if (!this.BackplaneApiUrl) {
      throw new Error("No VUE_BACKPLANE_API_URL env variable set");
    }
    if (!this.Env) {
      throw new Error("No VUE_APP_ENV env variable set");
    }
    if (!this.Version) {
      throw new Error("No VUE_APP_VERSION env variable set");
    }
  },
};
